
































































































































































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class LandingPageSmartGoals extends Vue {

  goals: Array<{ name: string, items: Array<any> }> = [
    {
      name: 'Get more productive',
      items: []},
    {
      name: 'Improve time management',
      items: []},
    {
      name: 'Become more organized',
      items: [
        'Declutter my desk',
        'Cancel subscriptions',
        'Quick inbox cleanup',
        'Set a weekly budget',
      ]},
  ];

  mounted() {
    this.$store.commit('set_onboarding_use_case', 'personal');
  }
}
